<template>
    <div>
        <!-- Start Header Area -->
        <HeaderOnePageTwo class="d-xs-none d-sm-none d-md-flex hidden-mobile" />
        <HeaderOnePageTwoMobile class="d-xs-flex d-sm-flex d-md-none" />
        <!-- End Header Area -->

        <!-- Start Slider Area -->
        <div class="slide slide-style-2 slider-video-bg d-flex align-center justify-center overlay-fix"
            data-black-overlay="1" style="height:30vh !important;">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <h2 class="heading-title1 text-h2 text-left font-weight-bold"
                            style="font-size: 80px !important; line-height: 90px !important; letter-spacing: 2px !important;">
                            Article
                        </h2>
                    </v-col>
                    <v-col cols="12" class="inner text-left d-flex flex-column align-start pt-0">
                        <h5 class="heading-title1 heading-subtitle text-h5 font-weight-light"
                            style="line-height: 40px !important;">
                            <!-- Streamline Your Geospatial Data Pipeline<br>Process, Analyze, and Visualize Geospatial Data with Ease -->
                            Insights and Ideas: Explore Our Curated Articles.
                        </h5>
                    </v-col>
                </v-row>
            </v-container>

            <!-- <div class="video-background">
                <span>
                    <video muted="" autoplay="" playsinline="" loop="" poster="">
                        <source src="../assets/images/bg/marine-slider-vid.mp4" />
                    </video>
                </span>
            </div> -->

            <div class="photo-background">
                <span>
                    <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-image: linear-gradient(to bottom, rgba(42, 42, 46, 0.50) 50%, rgb(41, 41, 44));"></div>
                    <img src="../assets/images/bg/silhouette-people-city.jpg" alt=""
                        style="background-repeat: no-repeat; background-size: cover; background-position: center center;" />
                </span>
            </div>
        </div>
        <!-- End Slider Area -->
        <v-toolbar color="#dd8732" class="toolbar-container" ref="toolbar">
            <v-row>
                <v-col></v-col>
                <v-col cols="12" sm="11" md="10" lg="9" xl="8">
                    <v-text-field class="customTextField" placeholder="Search By Keyword" dense hide-details
                        v-model="filterParameters">
                        <template v-slot:append-outer>
                            <v-btn block style="background-color: rgb(21, 41, 57)" v-on:click="filterPerspective()" elevation="0">
                                <span style="color: white;">Filter</span>
                            </v-btn>
                        </template>
                        <template v-slot:append>
                            <v-btn icon v-on:click="resetFilterPerspective">
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col></v-col>
            </v-row>
        </v-toolbar>
        <div style="min-height: 10vh; padding: 100px;" class="bg_color--5">
            <v-container>
                <v-row>
                    <v-col md="12" sm="12" v-bind:key="index" v-for="(item, index) in perspectiveContentsHighlighted">
                        <div class="container-article"> 
                            <v-img :src="item.image" class="image-article">
                                <v-card :href="item.link" target="_blank" class="highlighted-mobile d-flex align-end card-article pb-2 pl-6" flat tile>
                                    <v-card-title style="overflow-wrap: break-word; word-wrap: break-word; word-break: break-word;">
                                        <span class="source-title">{{ item.source }}</span>
                                        <h3 class="heading-title2"> {{ item.title }} </h3>
                                    </v-card-title>
                                </v-card>
                                <div class="overlay-article"></div>
                            </v-img>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="4" sm="12" v-bind:key="index" v-for="(item, index) in perspectiveContentsUnhighlighted">
                        <div class="container-article"> 
                            <v-img :src="item.image" class="image-article2">
                                <v-card :href="item.link" target="_blank" class="card-article2" flat tile>
                                    <v-card-title style="overflow-wrap: break-word; word-wrap: break-word; word-break: break-word;">
                                        <span class="source-title" style="font-size: 18px;">{{ item.source }}</span>
                                        <h5> {{ item.title }} </h5>
                                    </v-card-title>
                                    <v-card-text>
                                        <!-- <strong>
                                            {{ item.description }}
                                        </strong> -->
                                    </v-card-text>
                                </v-card>
                                <div class="overlay-article"></div>
                            </v-img>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <!-- Start Content -->
        <!-- <v-tabs-items v-model="tabContent">
            <v-tab-item>
                <SAP />
            </v-tab-item>
            <v-tab-item>
                <PersonalFinancialManagement />
            </v-tab-item>
            <v-tab-item>
                <OperationalDataStoreMartVue />
            </v-tab-item>
        </v-tabs-items> -->
        <!-- End Content -->
        <Footer />
    </div>
</template>
  
<script>
import HeaderOnePageTwo from "../components/header/HeaderOnePageTwoSiber.vue";
import HeaderOnePageTwoMobile from "../components/header/HeaderOnePageTwoSiberMobile.vue";
import Footer from "../components/footer/Footer";
import feather from "feather-icons";

// import Datapipe from './Datapipe.vue'
import SAP from "./SAP.vue";
import OperationalDataStoreMartVue from './LimitManagementSystem.vue';
import PersonalFinancialManagement from './PersonalFinancialManagement.vue';

export default {
    components: {
        SAP,
        HeaderOnePageTwo,
        HeaderOnePageTwoMobile,
        OperationalDataStoreMartVue,
        PersonalFinancialManagement,
        Footer,
    },
    methods: {
        iconSvg(icon) {
            return feather.icons[icon].toSvg()
        },
        filterPerspective() {
            let filter = this.perspectiveContents.filter(e => e.title.toLowerCase().includes(this.filterParameters.toLowerCase()))

            console.log(this.perspectiveContents, filter)

            this.perspectiveContentsFiltered = filter
        },
        resetFilterPerspective() {
            console.log(this.perspectiveContentsFiltered, this.perspectiveContents)
            this.perspectiveContentsFiltered = this.perspectiveContents
            this.filterParameters = ''
        }
    },
    computed: {
        perspectiveContentsHighlighted() {
            return [this.perspectiveContentsFiltered[0]]
        },
        perspectiveContentsUnhighlighted() {
            return this.perspectiveContentsFiltered.filter((e, index) => index != 0)
        }
    },
    mounted() {
        this.perspectiveContentsFiltered = this.perspectiveContents
    },
    data() {
        return {
            filterParameters: '',
            perspectiveContentsFiltered: [],
            perspectiveContents: [
                {
                    source: 'Kompas',
                    title: 'Pemanfaatan Data "Real Time" Mampu Tingkatkan Daya Saing Perusahaan',
                    image: require("../assets/images/linkto/5f3b4221a5d23.jpg"),
                    link: 'https://money.kompas.com/read/2022/09/30/070000126/pemanfaatan-data-real-time-mampu-tingkatkan-daya-saing-perusahaan',
                },
                {
                    source: 'Liputan 6',
                    title: 'Perbankan Tingkatkan Pelayanan Melalui Digitalisasi dan Artificial Intelligence',
                    image: require("../assets/images/linkto/058548800_1664357219-WhatsApp_Image_2022-09-28_at_15.14.11.webp"),
                    link: 'https://www.liputan6.com/news/read/5082787/perbankan-tingkatkan-pelayanan-melalui-digitalisasi-dan-artificial-intelligence',
                },
                {
                    source: 'DetikFinance',
                    title: 'Bank Ramai-ramai Mulai Manfaatkan "Robot" buat Pelayanan',
                    image: require("../assets/images/linkto/ilustrasi-robot-pengganti-pns_169.jpeg"),
                    link: 'https://finance.detik.com/berita-ekonomi-bisnis/d-6317590/bank-ramai-ramai-mulai-manfaatkan-robot-buat-pelayanan'
                },
                {
                    source: 'Katadata',
                    title: 'Active Intelligence Mampu Tingkatkan Daya Saing Perusahaan',
                    image: require("../assets/images/linkto/Active_Intelligence_Mampu_Tingkatkan_Daya_Saing_Perusahaan-2022_09_29-15_06_45_bbdb5b4f246e592bf7d6d708f26280cb_620x413_thumb.png"),
                    link: 'https://katadata.co.id/doddyrosadi/berita/63355266d9c18/active-intelligence-mampu-tingkatkan-daya-saing-perusahaan',
                },
                {
                    source: 'IDN Times',
                    title: 'Tingkatkan Daya Saing, Perusahaan Semakin Perlu Active Intelligence',
                    image: require("../assets/images/linkto/gunakan-teknologi-sebaik-mungkin-untuk-memudahkan-kamu-dalam-belajar-bdebec83b1206d3bcde02644ff9511a7-b29fc849de75660c05960e5dfafd61e5_600x400.jpg"),
                    link: 'https://jabar.idntimes.com/news/indonesia/galih/tingkatkan-daya-saing-perusahaan-semakin-perlu-active-intelligence',
                },
                {
                    source: 'RCTI+',
                    title: 'Upaya Pacu Daya Saing Perusahaan Lewat Pemanfaatan Active Intelligence',
                    image: require("../assets/images/linkto/2996382.webp"),
                    link: 'https://www.rctiplus.com/news/detail/nasional/2996382/upaya-pacu-daya-saing-perusahaan-lewat-pemanfaatan-active-intelligence',
                },
            ],
            
        };
    },
    watch: {
        
    }
};
</script>
<style scoped>
.heading-title1 {
  color: #e8ebf0 !important;
  line-height: 61px;
  font-family: 'Urbanist', sans-serif !important;
}
.heading-title2 {
  color: #1f1f25 !important;
  font-size: 42px;
  font-family: 'Urbanist', sans-serif !important;
}

.source-title {
  color: #dd8732 !important;
  letter-spacing: 5px;
  font-family: 'Outfit', sans-serif !important;
}

.item-subtitle {
    color: #E18122 !important;
    font-family: 'Urbanist', sans-serif !important;

}

.image-article {
    z-index: 0;
    border-radius: 5px !important;
    /* display: block; */
    width: auto;
    height: 470px;
}

.image-article2 {
    z-index: 0;
    border-radius: 5px !important;
    height: 270px;
}

.card-article {
    position:absolute;
    height: 100%;
    width: 50%;
    bottom:0%;
    z-index: 1;
    opacity: 0.9;
}
.card-article2 {
    position: absolute;
    width: auto;
    height: 50%;
    bottom: 0%;
    z-index: 1;
    opacity: 0.9;
}

.overlay-article {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #eeeeee;
}

.container-article {
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    position: relative;
}

.container-article:hover .overlay-article {
  opacity: 0.7;
  
}
.container-article:hover .card-article {
  opacity: 0.7;
  transition: .5s ease;
  background-color: #3b4950;
}
.container-article:hover .card-article2 {
  opacity: 0.5;
  transition: .5s ease;
  background-color: #3b4950;
}
.card-article:hover {
    transition: .5s ease;
    color: #e8ebf0 !important;
}
.card-article2:hover {
    transition: .5s ease;
    color: #e8ebf0 !important;
}


.bg-feature {
    background-image: linear-gradient(45deg, #232526, #414345);
}

.center-items-title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.active-tab {
    color: black;
}

.customTextField ::placeholder {
    font-size: 20px;
    color: black !important;
}


@media screen and (max-width: 767px) {
    .highlighted-mobile {
        width: 100% !important;
        font-size: 20px;
        background-color: #dd8732;
    }
}
</style>
  